import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useCallback, useState } from "react"
import Layout from "../../components/_App/layout"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import Papa from 'papaparse';
import DownloadAndCopyButtons from "../../helpers/buttons"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function CsvToJson() {
  const [jsonData, setJsonData] = useState(null);
  const [fileData, setFileData] = useState(null)
  const [message, setMessage] = useState("");
  const showMessage = (msg) => {
    setMessage(msg);
  };

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    setFileData(file)

    Papa.parse(file, {
      header: true,
      complete: handleCsvParsed,
    });
  }, []);

  const handleCsvParsed = (result) => {
    setJsonData(result.data);
  };

  return (
    <Layout>
      <Seo title="Free CSV to JSON Conversion Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>CSV to JSON</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="CSV to JSON" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="file_input_container">
                  <label htmlFor="CSV_file" className={convertStyled.input_file_upload}> <p> Upload CSV File</p></label>
                  <input
                    type="file"
                    className={convertStyled.file_input}
                    onChange={handleFileUpload}
                    accept=".csv"
                    id="CSV_file"
                  />
                </div>
                <DownloadAndCopyButtons disabled={!fileData} isJson={true} previewValue={JSON.stringify(jsonData, null, 2)} showMessage={showMessage}/>  {/*This is Button's code-------------------------->*/}
                {message && (<CopiedMessage message={message} />)}
              </div>
              <div className="col-lg-6">

                <h4>JSON Data:</h4>
                {jsonData && (
                  <div className="mt-3" style={{ height: "400px", overflow: "auto" }}>
                    <pre>
                <code>{JSON.stringify(jsonData, null, 2)}</code>
              </pre>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />

      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default CsvToJson
